import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { useBrandQuery } from '../../queryHooks/metaQuery'
import Image from './ui/Image'
import { useTranslation } from '../../providers/localesProvider'

const HexMessage: React.FC<{ children?: React.ReactNode; hideLogo?: boolean }> = ({ children, hideLogo = false }) => {
  const { data: ssrBrands } = useBrandQuery()
  const { t } = useTranslation()

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Grid item>
        <Grid item container spacing={2}>
          {!hideLogo && (
            <Grid item container justifyContent="center">
              <Image
                src={ssrBrands?.logo || ''}
                alt={t('brandName')}
                style={{ marginBottom: '20px', height: 'auto' }}
                width={73}
                height={200}
              />
            </Grid>
          )}

          <Grid item container justifyContent="center">
            <Typography align="center" sx={{ fontSize: 24, fontWeight: 'bold' }}>
              {children}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default HexMessage
