import { useRouter } from 'next/router'
import { FC, ReactNode, useEffect } from 'react'

import Spinner from './Spinner'
import { useAuth } from '../../providers/authProvider'

const AllowAnonymous: FC<{ children?: ReactNode; redirectTo?: string }> = ({ children, redirectTo }) => {
  const router = useRouter()
  const { isAuthenticated } = useAuth()
  const shouldRedirect = !!redirectTo

  useEffect(() => {
    if (shouldRedirect && isAuthenticated())
      router.push({ pathname: redirectTo, query: router.query }, undefined).then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRedirect, isAuthenticated])

  return shouldRedirect && isAuthenticated() ? <Spinner size="xlarge" /> : <>{children}</>
}

export default AllowAnonymous
