import React, { SyntheticEvent, useState } from 'react'
import { Box, Container, Grid, Tab, Tabs } from '@mui/material'

import { EventInterval } from '../enums'
import useVisibility from '../hooks/useVisibility'
import { useTranslation } from '../providers/localesProvider'
import { useEvents, useInfiniteEvents } from '../queryHooks/eventQuery'

import Spinner from './shared/Spinner'
import EventCard from './shared/EventCard'
import HexMessage from './shared/HexMessage'
import { FeaturedEventsCarousel } from './FeaturedEventsCarousel'

const HomeView: React.FC = () => {
  const { t } = useTranslation()
  const [tabValue, setTabValue] = useState<EventInterval>(EventInterval.upcoming)
  const { data: events, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading } = useInfiniteEvents(tabValue, false)
  const [currentElement] = useVisibility<HTMLDivElement>(async () => {
    if (fetchNextPage && !isFetchingNextPage) await fetchNextPage()
  }, 300)

  const { data: featuredEventsData } = useEvents({ isFeatured: true })
  const isFeaturedEvents = featuredEventsData && featuredEventsData?.length > 0

  const handleChangeTab = (_: SyntheticEvent, newValue: EventInterval) => setTabValue(newValue)

  return (
    <>
      {isLoading ? (
        <Spinner size="medium" />
      ) : (
        <Box sx={{ width: '100%' }}>
          <Grid>
            <Grid item container>
              <FeaturedEventsCarousel />
            </Grid>
            <Container>
              <Grid sx={{ my: 2 }}>
                <Tabs value={tabValue} onChange={handleChangeTab}>
                  <Tab value={EventInterval.upcoming} label={t('liveAndUpcomingEvents')} />
                  <Tab value={EventInterval.past} label={t('pastEvents')} />
                </Tabs>
              </Grid>
              <Grid item sx={{ py: 2 }}>
                <Grid container spacing={3} justifyContent="flex-start" alignItems="flex-start" direction="row">
                  {Array.isArray(events?.pages) && events?.pages.length > 0 ? (
                    events?.pages.map((page, pageIdx) => (
                      <React.Fragment key={`events-group-${pageIdx}`}>
                        {Array.isArray(page.data) &&
                          page.data.map((event, eventIdx) => {
                            if (
                              pageIdx === 0 &&
                              eventIdx === 0 &&
                              !isFeaturedEvents &&
                              tabValue === EventInterval.upcoming
                            )
                              return <React.Fragment key={event.uuid} />
                            return <EventCard key={event.uuid} eventData={event} />
                          })}
                      </React.Fragment>
                    ))
                  ) : (
                    <HexMessage hideLogo={true}>{t('noEvents')}</HexMessage>
                  )}
                </Grid>

                <br />

                <Grid
                  item
                  container
                  spacing={4}
                  style={{ paddingTop: 10, height: 34 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  {hasNextPage && <div ref={currentElement} />}
                  {isFetchingNextPage && <Spinner size="medium" />}
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Box>
      )}
    </>
  )
}

export default HomeView
