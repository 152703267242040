import { Grid, IconButton } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import useEmblaCarousel from 'embla-carousel-react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'

import { EventInterval } from '../enums'
import FeaturedEvent from './FeaturedEvent'
import { useDotButton } from '../hooks/carousel/useDotButtons'
import { useEvents, useInfiniteEvents } from '../queryHooks/eventQuery'
import { usePrevNextButtons } from '../hooks/carousel/usePrevNextButtons'

export function FeaturedEventsCarousel() {
  const { data: featuredEventsData } = useEvents({ isFeatured: true })
  const { data: upcomingEventsData } = useInfiniteEvents(EventInterval.upcoming, false)

  const eventsData =
    featuredEventsData && featuredEventsData?.length > 0
      ? featuredEventsData
      : upcomingEventsData?.pages[0].data.slice(0, 1)

  const isCarouselActive = !!eventsData?.length && eventsData.length > 1

  const [emblaRef, emblaApi] = useEmblaCarousel({ active: isCarouselActive })

  const { onNextButtonClick, onPrevButtonClick, prevBtnDisabled, nextBtnDisabled } = usePrevNextButtons(emblaApi)
  const { selectedIndex, onDotButtonClick } = useDotButton(emblaApi)

  return eventsData ? (
    <Grid sx={{ width: '100%', backgroundColor: '#f1f1f1', py: 1 }}>
      <Grid sx={{ overflow: 'hidden' }} ref={emblaRef}>
        <Grid sx={{ display: 'flex' }}>
          {eventsData?.map((item) => (
            <Grid key={item.id} sx={{ flex: '0 0 100%' }}>
              <FeaturedEvent eventData={item} />
            </Grid>
          ))}
        </Grid>
      </Grid>

      {isCarouselActive ? (
        <Grid item container p={2} gap={1} justifyContent="center" alignItems="center" flexWrap="nowrap">
          <Grid item>
            <IconButton
              size="small"
              sx={{ border: '1px solid gray' }}
              disabled={prevBtnDisabled}
              onClick={onPrevButtonClick}
            >
              <NavigateBeforeIcon />
            </IconButton>
          </Grid>

          <Grid
            container
            item
            alignItems="center"
            justifyContent="center"
            gap={0.5}
            sx={{ width: 'fit-content', maxWidth: 300 }}
          >
            {eventsData?.map((_, index) => (
              <IconButton key={index} onClick={() => onDotButtonClick(index)} sx={{ p: 0 }}>
                <CircleIcon color={index === selectedIndex ? 'primary' : 'inherit'} sx={{ fontSize: 15 }} />
              </IconButton>
            ))}
          </Grid>

          <Grid item>
            <IconButton
              size="small"
              sx={{ border: '1px solid gray' }}
              disabled={nextBtnDisabled}
              onClick={onNextButtonClick}
            >
              <NavigateNextIcon />
            </IconButton>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  ) : null
}
