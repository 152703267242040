import { memo } from 'react'
import { Avatar, Chip, Container, Grid, Typography } from '@mui/material'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'

import Link from './shared/ui/Link'
import { HexEvent } from '../types'
import Image from './shared/ui/Image'
import Button from './shared/ui/Button'
import { useDayJs } from '../hooks/useDayJs'
import EventShare from './shared/EventShare'
import EventFavorite from './shared/EventFavorite'
import { useBrandQuery } from '../queryHooks/metaQuery'
import { useTranslation } from '../providers/localesProvider'

type Props = { eventData?: HexEvent }

function FeaturedEvent(props: Props) {
  const { eventData } = props
  const { t } = useTranslation()
  const { data: ssrBrands } = useBrandQuery()
  const { format, formatRange, formatDateDayRange, monthAbbreviationFormat } = useDayJs()

  const isShowStartTime = eventData?.timeSettings?.isShowStartTime
  const isShowEndTime = eventData?.timeSettings?.isShowEndTime

  const eventStartTimeStamp = eventData?.timeSlotsTimestampRange?.from ?? 0
  const eventEndTimeStamp = eventData?.timeSlotsTimestampRange?.to ?? 0

  const { timeZone, isUseLocalTimeZone } = eventData?.timeSettings ?? {}
  const eventTimeZone = !isUseLocalTimeZone ? timeZone?.standardName : undefined

  const eventDateDayFormat = formatDateDayRange({
    startTimeStamp: eventStartTimeStamp,
    endTimeStamp: eventEndTimeStamp,
    timeZone: eventTimeZone,
  })

  return eventData ? (
    <Grid container sx={{ width: '100%', minHeight: '350px' }}>
      <Container>
        <Grid container gap={{ md: 5 }} alignItems="center" sx={{ height: '100%' }}>
          <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
            <Image
              priority
              src={eventData.details?.coverMedia?.url}
              alt={eventData.title}
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
                maxHeight: '300px',
                borderRadius: '12px',
              }}
              width={834}
              height={417}
            />
            {eventStartTimeStamp ? (
              <Avatar
                sx={{
                  minWidth: 60,
                  width: 'auto',
                  px: 1,
                  height: 50,
                  fontSize: 14,
                  fontWeight: 800,
                  borderRadius: 1,
                  textAlign: 'center',
                  boxSizing: 'border-box',
                  backgroundColor: '#F7F7F7',
                  border: '1px solid #E1E1E1',
                  color: 'common.black',
                  position: 'absolute',
                  top: 15,
                  right: 15,
                  flexDirection: 'column',
                }}
              >
                <span style={{ marginBottom: '4px' }}>{eventDateDayFormat}</span>
                <Grid sx={{ color: 'primary.main' }}>
                  <span>
                    {format({
                      timeStamp: eventStartTimeStamp,
                      format: monthAbbreviationFormat,
                      timeZone: eventTimeZone,
                    })}
                  </span>
                </Grid>
              </Avatar>
            ) : null}
            {eventData?.category?.name ? (
              <Chip
                label={eventData.category.name}
                color="primary"
                sx={{ position: 'absolute', top: 15, left: 15, fontWeight: 700, borderRadius: 0.5 }}
              />
            ) : null}
          </Grid>
          <Grid container gap={1} item xs={12} md={4} sx={{ mb: { xs: 2, md: 0 } }}>
            <Grid container>
              {ssrBrands?.isDisableSocialShare ? null : (
                <EventShare
                  size="small"
                  iconFontSize="small"
                  url={`/${eventData?.uriTitle}`}
                  sx={{ color: 'primary.main' }}
                />
              )}
              {ssrBrands?.isDisableFavorite ? null : <EventFavorite event={eventData} color="primary" />}
            </Grid>

            <Typography sx={{ fontSize: 35, fontWeight: 600 }}>{eventData?.title}</Typography>

            {eventData?.location?.fullAddress ? (
              <Grid container sx={{ flexWrap: 'nowrap' }}>
                <LocationOnOutlinedIcon sx={{ mr: 1, fontSize: 20 }} />
                <Typography sx={{ fontSize: 14 }} suppressHydrationWarning>
                  {eventData.location.fullAddress}
                </Typography>
              </Grid>
            ) : null}

            {eventStartTimeStamp || eventEndTimeStamp ? (
              <Grid container sx={{ mb: 1, flexWrap: 'nowrap' }}>
                <AccessTimeOutlinedIcon sx={{ mr: 1, fontSize: 20 }} />
                <Typography sx={{ fontSize: 14 }} suppressHydrationWarning>
                  {formatRange({
                    startTimeStamp: eventStartTimeStamp,
                    endTimeStamp: eventEndTimeStamp,
                    isShowStartTime,
                    isShowEndTime,
                    timeZone: eventTimeZone,
                  })}
                </Typography>
              </Grid>
            ) : null}
            {eventData?.isShowOrganizerInEventPage && eventData?.organizer?.uriTitle ? (
              <Grid container item alignItems="center" gap={0.5} mb={1}>
                <Typography ml={0.5}>{`${t('organizer')}: `}</Typography>
                <Link
                  href={{
                    pathname: `/organizers/${eventData.organizer.uriTitle}`,
                    query: { organizerId: eventData.organizer.uriTitle },
                  }}
                  passHref
                >
                  <Typography sx={{ fontWeight: 'bold', fontSize: 14 }} color="primary">
                    {eventData.organizer?.name}
                  </Typography>
                </Link>
              </Grid>
            ) : null}
            <Link href={{ pathname: `/${eventData?.uriTitle}` }} passHref>
              <Button sx={{ width: { xs: '100%', md: 'initial' } }}>{t('eventDetails')}</Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  ) : null
}

export default memo(FeaturedEvent)
