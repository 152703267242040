import { FC } from 'react'
import { withSSRContext } from 'aws-amplify'
import { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next'
import { dehydrate, QueryClient } from '@tanstack/react-query'

import log from '../helpers/log'
import { EventInterval } from '../enums'
import { getHost, isServer } from '../helpers/common'
import { eventsInfiniteQueryOptions, eventsQueryOptions } from '../queryHooks/eventQuery'

import { LocaleCode } from '../enums'
import HomeView from '../components/HomeView'
import AllowAnonymous from '../components/shared/AllowAnonymous'
import { getSSRLocalCode } from '../providers/localesProvider'
import { getCustomerSettings } from '../services/metaDataService'

type serverSideProps = InferGetServerSidePropsType<typeof getServerSideProps> & { dehydratedState?: unknown }

const Home: FC<serverSideProps> = () => {
  return (
    <AllowAnonymous>
      <HomeView />
    </AllowAnonymous>
  )
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const host = getHost(context.req)
  let idToken = ''
  const queryClient = new QueryClient()

  const { Auth } = withSSRContext(context)
  const isCSR = context.req.url?.startsWith('/_next')

  if (isCSR) {
    return {
      props: {},
    }
  }

  try {
    const authRes = await Auth.currentSession()
    idToken = authRes.getIdToken().getJwtToken()
  } catch (e) {
    log.info('User not logged in', { e })
  }

  let userLocaleCode = LocaleCode.english

  try {
    const customerSettings = await getCustomerSettings({
      headers: { 'X-HOST': host, ...(idToken && { Authorization: `Bearer ${idToken}` }) },
    })
    userLocaleCode = getSSRLocalCode({ req: context.req, customerSettingsData: customerSettings.data })
  } catch (e) {
    if (isServer()) log.info('Get Customer Settings failed', e)
  }

  try {
    await Promise.all([
      queryClient.prefetchQuery(
        eventsQueryOptions({
          isFeatured: true,
          headers: {
            'X-Host': host,
            Authorization: idToken ? `Bearer ${idToken}` : undefined,
            'Accept-Language': userLocaleCode,
          },
        })
      ),
      queryClient.prefetchInfiniteQuery(
        eventsInfiniteQueryOptions({
          interval: EventInterval.upcoming,
          isFeatured: false,
          requestConfig: {
            headers: {
              'X-Host': host,
              Authorization: idToken ? `Bearer ${idToken}` : undefined,
              'Accept-Language': userLocaleCode,
            },
          },
        })
      ),
      queryClient.prefetchInfiniteQuery(
        eventsInfiniteQueryOptions({
          interval: EventInterval.past,
          isFeatured: false,
          requestConfig: {
            headers: {
              'X-Host': host,
              Authorization: idToken ? `Bearer ${idToken}` : undefined,
              'Accept-Language': userLocaleCode,
            },
          },
        })
      ),
    ])
  } catch (e) {
    if (isServer()) log.info('Get events failed', e)
  }

  return {
    props: {
      dehydratedState: JSON.parse(
        JSON.stringify(dehydrate(queryClient))
      ) /* stringify & parse to fix serializing error */,
    },
  }
}

export default Home
